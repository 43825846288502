import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Box,
  Divider,
  Paper,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/system";
import { searchService } from "../../services/search.service";

const StyledDialogContentText = styled(DialogContentText)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
}));

const SectionContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  borderLeft: `5px solid ${theme.palette.primary.main}`,
}));

const blinkAnimation = {
  animation: "blink 1s linear infinite",
  "@keyframes blink": {
    "0%": { opacity: 1 },
    "10%": { opacity: 0.8 },
    "20%": { opacity: 0.6 },
    "30%": { opacity: 0.4 },
    "40%": { opacity: 0.2 },
    "50%": { opacity: 0.3 },
    "60%": { opacity: 0.5 },
    "70%": { opacity: 0.7 },
    "80%": { opacity: 0.9 },
    "90%": { opacity: 0.95 },

    "100%": { opacity: 1 },
  },
};

const BlinkingSkeleton = styled(Skeleton)({
  ...blinkAnimation,
});

const PatentProductRankingDetail = ({
  open,
  handleClose,
  patentProduct,
  searchId,
  choosenClaimIndex,
}) => {
  const [analysis, setAnalysis] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadDetail = async () => {
    try {
      const analysisData = await searchService.explainResult(
        searchId,
        patentProduct,
        choosenClaimIndex
      );
      console.log(analysisData);
      setAnalysis(analysisData.analysis);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (patentProduct) {
      setLoading(true);
      loadDetail();
    } else {
      setLoading(false);
    }
  }, [patentProduct]);
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      {loading ? (
        <DialogContent>
          <BlinkingSkeleton variant="text" width="80%" height={40} />
          <BlinkingSkeleton variant="text" width="60%" height={30} />
          <BlinkingSkeleton variant="rectangular" width="100%" height={80} />
          <Box mt={2} mb={2}>
            <BlinkingSkeleton variant="rectangular" width="100%" height={200} />
          </Box>
          <BlinkingSkeleton variant="rectangular" width="100%" height={200} />
        </DialogContent>
      ) : (
        analysis && (
          <>
            <DialogTitle>
              {patentProduct && (
                <Box display="flex" justifyContent="space-between">
                  <strong variant="h5" component="div" color="primary">
                    {patentProduct.title}
                  </strong>
                </Box>
              )}
            </DialogTitle>
            <DialogContent>
              <Box mb={2}></Box>
              <SectionContainer>
                <Typography variant="subtitle1" color="primary" gutterBottom>
                  Similarities
                </Typography>
                {analysis.similarities && analysis.similarities.length > 0 ? (
                  analysis.similarities.map((similarity, index) => (
                    <StyledDialogContentText key={index} component="div">
                      <Typography variant="body1" gutterBottom>
                        <strong>Reasoning:</strong> {similarity.reasoning}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        <strong>Claim Reference:</strong>{" "}
                        {similarity.claim_reference}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        <strong>Website Reference:</strong>{" "}
                        {similarity.website_reference}
                      </Typography>
                      {index < analysis.similarities.length - 1 && (
                        <StyledDivider />
                      )}
                    </StyledDialogContentText>
                  ))
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    No similarities available.
                  </Typography>
                )}
              </SectionContainer>
              <SectionContainer>
                <Typography variant="subtitle1" color="primary" gutterBottom>
                  Differences
                </Typography>
                {analysis.differences && analysis.differences.length > 0 ? (
                  analysis.differences.map((difference, index) => (
                    <StyledDialogContentText key={index} component="div">
                      <Typography variant="body1" gutterBottom>
                        <strong>Reasoning:</strong> {difference.reasoning}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        <strong>Claim Reference:</strong>{" "}
                        {difference.claim_reference}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        <strong>Website Reference:</strong>{" "}
                        {difference.website_reference}
                      </Typography>
                      {index < analysis.differences.length - 1 && (
                        <StyledDivider />
                      )}
                    </StyledDialogContentText>
                  ))
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    No differences available.
                  </Typography>
                )}
              </SectionContainer>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                variant="outlined"
                sx={{ margin: "20px 30px 10px 10px" }}
              >
                Close
              </Button>
            </DialogActions>
          </>
        )
      )}
    </Dialog>
  );
};

export default PatentProductRankingDetail;
