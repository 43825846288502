import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const Footer = () => {
  const VERSION = `${process.env.REACT_APP_API_URL}/get_version`;
  const [version, setVersion] = useState("");
  const [versionApp, setVersionApp] = useState("");
  const currentYear = new Date().getFullYear(); // Get the current year dynamically

  useEffect(() => {
    loadVersionApi();
  }, []);

  const loadVersionApi = async () => {
    try {
      const response = await fetch(VERSION);
      const result = await response.json();
      console.log(result);
      setVersion(result);
    } catch (error) {
      console.error("Error fetching version:", error);
    }
  };

  useEffect(() => {
    fetch("version.txt")
      .then((response) => response.text())
      .then((text) => {
        setVersionApp(text);
      });
  }, []);

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "lightgrey",
        color: "white",
        textAlign: "center",
        position: "fixed",
        width: "100%",
        bottom: 0,
        left: 0,
        margin: 0,
        padding: 0,
      }}
    >
      <Typography variant="body2" sx={{ padding: "5px 0" }}>
        © PioneerIP {currentYear} &nbsp;&nbsp;|&nbsp;&nbsp; Version: API&nbsp;
        {version?.version} &nbsp;|&nbsp; App{" "}
        {versionApp?.startsWith("v") ? versionApp : ""}
      </Typography>
    </Box>
  );
};

export default Footer;
