const formatDateWithoutTime = (dateString) => {
  const date = new Date(dateString);
  const options = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
};
const formatDateWithHourAndMinute = (dateString) => {
  const date = new Date(dateString);
  const options = {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return date.toLocaleDateString("en-US", options);
}
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return date.toLocaleDateString("en-US", options);
};

const exportToCSV = (data, fileName, headers = []) => {
  if (data.length === 0) return;

  const mainHeaders = Object.keys(data[0]);
  const csvRows = [];

  // Add extra headers (if provided)
  if (headers.length > 0) {
    headers.forEach((headerRow) => {
      csvRows.push(headerRow); // Each header row is added as is
    });
  }

  // Add main headers
  csvRows.push(mainHeaders.join(","));

  // Add data rows
  data.forEach((row) => {
    const values = mainHeaders.map((header) => {
      const value = row[header];
      const escaped = `${value}`.replace(/"/g, '""');
      return `"${escaped}"`;
    });
    csvRows.push(values.join(","));
  });

  const csvString = csvRows.join("\n");
  const blob = new Blob([csvString], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();

  window.URL.revokeObjectURL(url);
};

export const utilsService = {
  formatDateWithoutTime,
  formatDate,
  exportToCSV,
  formatDateWithHourAndMinute,
};

