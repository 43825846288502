import React, { useState, useEffect } from "react";
import {
  Box,
  InputBase,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  useMediaQuery,
  Snackbar,
  Alert,
  Tabs,
  Tab,
  Typography,
  Drawer,
  Divider,
  Tooltip,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import PaginationFormControl from "../pagination-control";
import PatentInfo from "../patent-product/patent-info";
import PatentProductList from "./patent-product-list";
import { utilsService } from "../../services/utils";
import { searchService } from "../../services/search.service";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const PatentSearchControls = ({
  claimsIndexes,
  choosenClaimIndex,
  setChoosenClaimIndex,
  itemsPerPage,
  setItemsPerPage,
  currentPage,
  setCurrentPage,
  searchProductTerm,
  setSearchProductTerm,
  PatentProducts,
  setPatentProducts,
  AllPatentProducts,
  setAllPatentProducts,
  error,
  setError,
  patentInfo,
  querySearch,
  setQuerySearch,
  dataSearchId,
  setDataSearchId,
  updatedAt,
  status,
  loading,
}) => {
  const [isCarouselVisible, setIsCarouselVisible] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  console.log("claimsIndexes&&&&&&****", claimsIndexes);
  console.log(
    "choosenClaimIndex&&&&&&**** type",
    typeof choosenClaimIndex,
    choosenClaimIndex
  );
  const [filterRating, setFilterRating] = useState("");
  const [filterFeedback, setFilterFeedback] = useState("");

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };
  useEffect(() => {
    handleSearchPatentProduct();
  }, [filterRating, searchProductTerm, AllPatentProducts, filterFeedback]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const sortPatentProducts = (products) => {
    return products.slice().sort((a, b) => {
      if (a.rating === 1 && b.rating !== 1) return -1;
      if (b.rating === 1 && a.rating !== 1) return 1;
      if (a.rating === -1 && b.rating !== -1) return 1;
      if (b.rating === -1 && a.rating !== -1) return -1;
      if (a.rating === b.rating) {
        return b.rating_auto - a.rating_auto; // Secondary sort by rating_auto
      }
      return b.rating - a.rating;
    });
  };

  const handleSearchPatentProduct = () => {
    const searchTermQueryLowerCase = searchProductTerm.toLowerCase();

    const filteredBySearchTerm = AllPatentProducts.filter(
      (patent) =>
        patent.title.toLowerCase().includes(searchTermQueryLowerCase) ||
        patent.snippet.toLowerCase().includes(searchTermQueryLowerCase)
    );

    const filteredByRating = filteredBySearchTerm.filter((patent) => {
      if (filterRating === "rating_auto_greater_or_equal_0.75") {
        return patent.rating_auto >= 0.75;
      } else if (
        filterRating === "rating_auto_less_0.75_and_greater_or_equal_0.25"
      ) {
        return patent.rating_auto < 0.75 && patent.rating_auto >= 0.25;
      } else if (filterRating === "rating_auto_less_0.25") {
        return patent.rating_auto < 0.25;
      } else {
        return true;
      }
    });

    const filteredPatents = filteredByRating.filter((patent) => {
      if (filterFeedback === "1") {
        return patent.rating === 1;
      } else if (filterFeedback === "-1") {
        return patent.rating === -1;
      } else {
        return true;
      }
    });

    setPatentProducts(sortPatentProducts(filteredPatents));
    setCurrentPage(1);
  };

  const handleExportPatentProductsToExcel = () => {
    const exportData = PatentProducts.map((patent) => ({
      Title: patent.title,
      Snippet: patent.snippet,
      Source: patent.source,
      Link: patent.link,
      score: patent.rating_auto,
    }));

    const headers = [
      "Powered by PioneerIP - Web based infringement search of granted and pending claims",
      "Visit us www.PioneerIP.com"
    ];

    utilsService.exportToCSV(exportData, `PioneerIP_${querySearch}_${choosenClaimIndex}`, headers);
  };

  const handleFeedbackUpdate = async (searchId, patentUid, newRating) => {
    const extractedSearchId = searchId.$oid || searchId;

    try {
      const updatedProducts = PatentProducts.map((patent) =>
        patent.uid === patentUid ? { ...patent, rating: newRating } : patent
      );

      setPatentProducts(sortPatentProducts(updatedProducts));

      setAllPatentProducts((prevProducts) =>
        prevProducts.map((patent) =>
          patent.uid === patentUid ? { ...patent, rating: newRating } : patent
        )
      );

      await searchService.updateUserRating(
        extractedSearchId,
        patentUid,
        newRating
      );
    } catch (error) {
      console.error("Failed to update rating: " + error.message);
    }
  };

  const toggleCarouselVisibility = () => {
    setIsCarouselVisible(!isCarouselVisible);
  };

  const currentItems =
    PatentProducts?.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    ) || [];
  const handleNavigateToPatentPage = () => {
    if (params.archiveId) {
      navigate("/patent-products");
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: "60px",
          zIndex: "100",
          backgroundColor: "white",
          padding: "10px 0",
          transform: "translateZ(0)",
        }}
      >
        {patentInfo && (
          <PatentInfo
            patentInfo={patentInfo}
            querySearch={querySearch}
            toggleCarouselVisibility={toggleCarouselVisibility}
            isCarouselVisible={isCarouselVisible}
            updatedAt={updatedAt}
            status={status}
          />
        )}
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
        >
          <Box
            sx={{
              width: 750, // Width of the drawer
              padding: "20px", // Padding inside the drawer
            }}
          >
            {/* Map through the claimsIndexes to find the matching claim */}
            {claimsIndexes.map((claim) =>
              parseInt(claim.claimIndex, 10) === choosenClaimIndex ? (
                <Box key={claim.claimIndex} mb={3}>
                  <Typography variant="h6" color="primary">
                    Claim {claim.claimIndex}
                  </Typography>
                  <Divider sx={{ my: 2 }} />{" "}
                  {/* Add a divider between sections */}
                  {/* Claim content */}
                  <Typography
                    variant="body1"
                    sx={{
                      lineHeight: 1.6, // Line height for better readability
                      marginBottom: "16px", // Space between paragraphs
                    }}
                  >
                    {claim.claim_text}
                  </Typography>
                </Box>
              ) : null
            )}
          </Box>
        </Drawer>

        <Box
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          {AllPatentProducts.length > 0 && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Box>
                  <Tabs
                    value={choosenClaimIndex} // choosenClaimIndex is a number
                    onChange={(event, newValue) => {
                      setChoosenClaimIndex(newValue);
                      setFilterRating("");
                      setFilterFeedback("");
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="Claims Tabs"
                    sx={{
                      "& .MuiTabs-indicator": {
                        backgroundColor: "primary.main", // Custom indicator color
                      },
                      "& .MuiTab-root": {
                        textTransform: "none", // Prevent all caps
                        fontWeight: "bold", // Bold text
                      },
                      "& .Mui-selected": {
                        color: "primary.main", // Active tab color (from theme)
                      },
                      "& .MuiTabScrollButton-root": {
                        color: "primary.main", // Scroll button color
                      },
                    }}
                  >
                    {claimsIndexes?.map((claim, index) => (
                      <Tab
                        disabled={claim.status !== "completed"}
                        key={parseInt(claim.claimIndex, 10)}
                        label={
                          <Tooltip
                            title={
                              claim.claim_text
                                ?.split(" ")
                                .slice(0, 20)
                                .join(" ") +
                              (claim.claim_text?.split(" ").length > 20
                                ? "..."
                                : "")
                            }
                            arrow
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{
                                  color:
                                    parseInt(claim.claimIndex, 10) ===
                                    choosenClaimIndex
                                      ? "#2393d8"
                                      : "gray",
                                }}
                              >
                                {claim.name}
                              </span>
                              <span
                                style={{
                                  fontSize: "0.6em",
                                  color:
                                    parseInt(claim.claimIndex, 10) ===
                                    choosenClaimIndex
                                      ? "#2393d8"
                                      : "gray",
                                }}
                              >
                                {claim.status}
                              </span>
                            </div>
                          </Tooltip>
                        }
                        value={parseInt(claim.claimIndex, 10)}
                      />
                    ))}
                  </Tabs>
                </Box>
              </Box>
              {/* <Button
                variant="outlined"
                // sx={{ color: "red", borderColor: "red" }}
                onClick={toggleDrawer(true)}
              >
                Show Claim Text
              </Button> */}
            </Box>
          )}
          {AllPatentProducts.length > 0 && (
            <Box display="flex" alignItems="center">
              <PaginationFormControl
                itemsPerPage={itemsPerPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
                pageCount={Math.ceil(PatentProducts.length / itemsPerPage)}
                currentPage={currentPage}
                handleChangePage={handleChangePage}
              />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            marginTop: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: isSmallScreen ? "column" : "row",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            {AllPatentProducts.length > 0 && (
              <InputBase
                placeholder="Search Patent Products"
                inputProps={{ "aria-label": "search" }}
                sx={{
                  // color: "inherit",
                  paddingLeft: "40px",
                  width: "100%",
                  borderRadius: "5px",
                  border: "1px solid rgb(128,128,128, 0.3)",
                  position: "relative",
                  height: "40px",
                  marginRight: isSmallScreen ? "0" : "10px",
                }}
                value={searchProductTerm}
                onChange={(e) => setSearchProductTerm(e.target.value)}
                onKeyDown={(e) =>
                  e.key === "Enter" && handleSearchPatentProduct()
                }
              />
            )}
            {AllPatentProducts.length > 0 && (
              <>
                <FormControl
                  variant="outlined"
                  // color="primary"
                  sx={{
                    width: "10em",
                    marginRight: "10px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      // borderColor: "rgb(25, 118, 210, 0.5)",
                    },
                    "& .MuiInputLabel-root": {
                      // color: "rgb(25, 118, 210)",
                    },
                  }}
                >
                  <InputLabel id="feedback-select-label" shrink>
                    Feedback
                  </InputLabel>
                  <Select
                    labelId="feedback-select-label"
                    sx={{ height: "2.5rem" }}
                    name="feedback"
                    value={filterFeedback}
                    onChange={(e) => setFilterFeedback(e.target.value)}
                    label="Feedback"
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value="1">Like</MenuItem>
                    <MenuItem value="-1">Dislike</MenuItem>
                  </Select>
                </FormControl>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    margin: "0 10px",
                    backgroundColor: "white",
                  }}
                >
                  <Typography sx={{ color: "gray" }}>Relevancy:</Typography>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ gap: "8px" }}
                  >
                    {/* high Circle */}
                    <Box
                      sx={{
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "30px",
                        height: "30px",
                        backgroundColor: "rgba(255, 0, 0, 0.5)",
                        color: "black",
                        fontWeight: "bold",
                        cursor: "pointer",
                        border:
                          filterRating === "rating_auto_greater_or_equal_0.75"
                            ? "2px solid red"
                            : "none",
                        "&:hover": {
                          opacity: 0.8,
                        },
                      }}
                      onClick={() =>
                        setFilterRating(
                          filterRating === "rating_auto_greater_or_equal_0.75"
                            ? ""
                            : "rating_auto_greater_or_equal_0.75"
                        )
                      }
                    >
                      <Typography fontSize="9px" sx={{ lineHeight: "normal" }}>
                        High
                      </Typography>
                    </Box>

                    {/* med Circle */}
                    <Box
                      sx={{
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "30px",
                        height: "30px",
                        backgroundColor: "rgba(255, 255, 0)",
                        color: "black",
                        fontWeight: "bold",
                        cursor: "pointer",
                        border:
                          filterRating ===
                          "rating_auto_less_0.75_and_greater_or_equal_0.25"
                            ? "2px solid red"
                            : "none",
                        "&:hover": {
                          opacity: 0.8,
                        },
                      }}
                      onClick={() =>
                        setFilterRating(
                          filterRating ===
                            "rating_auto_less_0.75_and_greater_or_equal_0.25"
                            ? ""
                            : "rating_auto_less_0.75_and_greater_or_equal_0.25"
                        )
                      }
                    >
                      <Typography fontSize="9px" sx={{ lineHeight: "normal" }}>
                        Med
                      </Typography>
                    </Box>

                    {/* low Circle */}
                    <Box
                      sx={{
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "30px",
                        height: "30px",
                        backgroundColor: "rgb(35, 147, 216, 0.5)",
                        color: "black",
                        fontWeight: "bold",
                        cursor: "pointer",
                        border:
                          filterRating === "rating_auto_less_0.25"
                            ? "2px solid red"
                            : "none",
                        "&:hover": {
                          opacity: 0.8,
                        },
                      }}
                      onClick={() =>
                        setFilterRating(
                          filterRating === "rating_auto_less_0.25"
                            ? ""
                            : "rating_auto_less_0.25"
                        )
                      }
                    >
                      <Typography fontSize="9px" sx={{ lineHeight: "normal" }}>
                        Low
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>{" "}
          {AllPatentProducts.length > 0 && (
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              sx={{
                width: isSmallScreen ? "100%" : "22%",
                height: "40px",
                marginLeft: isSmallScreen ? "0" : "10px",
              }}
              onClick={handleExportPatentProductsToExcel}
              startIcon={<DownloadIcon />}
            >
              Export
            </Button>
          )}
        </Box>
      </Box>

      {error && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setError("")}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          style={{ top: "100px", transform: "translateY(50px)" }}
        >
          <Alert
            onClose={() => setError("")}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress />
            <Typography variant="h6" sx={{ marginTop: "10px", color: "gray" }}>
              Almost there, showing results of your search...
            </Typography>
          </Box>
        </Box>
      ) : PatentProducts.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <Typography variant="h6" sx={{ color: "gray" }}>
            No results found.
          </Typography>
        </Box>
      ) : (
        <PatentProductList
          currentItems={currentItems}
          handleFeedbackUpdate={handleFeedbackUpdate}
          dataSearchId={dataSearchId}
          choosenClaimIndex={choosenClaimIndex}
          claimsIndexes={claimsIndexes}
          loading={loading}
        />
      )}
    </>
  );
};

export default PatentSearchControls;
