import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import PatentProductRankingDetail from "./patent-product-ranking-detail";
import { searchService } from "../../services/search.service";
import { showErrorMsg } from "../../services/event-bus.service";

// Load environment variable
const useDevFeatures = process.env.REACT_APP_USE_DEV_FEATURES === "true";

const PatentAnalysisMenu = ({
  anchorEl,
  open,
  handleClose,
  patentProduct,
  searchId,
  choosenClaimIndex,
}) => {
  const [openRankingDetail, setOpenRankingDetail] = useState(false);
  const [intelligenceReport, setIntelligenceReport] = useState(null);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);

  useEffect(() => {
    setIntelligenceReport((prevReport) => {
      if (prevReport?.uid === patentProduct?.uid) {
        return prevReport;
      }
      return patentProduct;
    });
  }, [patentProduct]);

  const handleOpenRankingDetail = () => {
    if (patentProduct && searchId) {
      setOpenRankingDetail(true);
    } else {
      console.error("Patent product or search ID is missing");
    }
  };

  const handleCloseRankingDetail = () => {
    setOpenRankingDetail(false);
    handleClose();
  };

  const getReportStatus = () => {
    switch (intelligenceReport?.report?.status) {
      case "completed":
        return "Intelligence Report is ready!";
      case "running":
        return "Report generation in progress";
      default:
        return "";
    }
  };

  const handleCreateReport = async () => {
    if (!patentProduct || !searchId || choosenClaimIndex === undefined) {
      showErrorMsg("Missing required parameters for report creation");
      return;
    }
    setStatusDialogOpen(true);
    handleClose();
    try {
      const reportData = await searchService.createIntelligenceReport(
        searchId,
        patentProduct,
        choosenClaimIndex
      );
      setIntelligenceReport(reportData);
    } catch (error) {
      console.error(error);
      showErrorMsg("Failed to generate report");
    }
  };

  const handleDownloadReport = (docxUrl) => {
    window.open(docxUrl, "_blank");
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleOpenRankingDetail}>
          Similarity Analysis
        </MenuItem>
        {useDevFeatures && (
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleCreateReport();
            }}
          >
            Intelligence Report
          </MenuItem>
        )}
      </Menu>

      <Dialog
        open={statusDialogOpen}
        onClose={() => setStatusDialogOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          {`Report${
            intelligenceReport?.report?.status
              ? ` - Status - ${intelligenceReport.report.status}`
              : ""
          }`}
        </DialogTitle>
        <DialogContent
          sx={{
            width: 400,
            height: 150,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.2rem",
            borderTop: (theme) =>
              intelligenceReport?.report?.status === "completed"
                ? `2px solid ${theme.palette.success.main}`
                : `2px solid ${theme.palette.primary.main}`,
          }}
        >
          <div style={{ textAlign: "center" }}>
            {intelligenceReport?.report?.status !== "completed" && (
              <CircularProgress sx={{ marginTop: 3 }} />
            )}
            <p>{getReportStatus()}</p>
            {intelligenceReport?.report?.status === "completed" && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  handleDownloadReport(intelligenceReport.report.docx)
                }
                sx={{ mt: 2 }}
              >
                Download Report
              </Button>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setStatusDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {patentProduct && searchId && (
        <PatentProductRankingDetail
          open={openRankingDetail}
          handleClose={handleCloseRankingDetail}
          patentProduct={patentProduct}
          searchId={searchId}
          choosenClaimIndex={choosenClaimIndex}
        />
      )}
    </>
  );
};

export default PatentAnalysisMenu;
